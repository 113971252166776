@function toRem($a) {
    @return ($a/37.5) + rem;
}
.scan-code{
    height: 100%;
    padding:0 toRem(14);
    overflow: hidden;
    .code{
        margin-top: toRem(15);
        background: rgba(255,255,255,0.6);
        border-radius: toRem(8);
        padding:toRem(30) 0 toRem(23) 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        div:nth-child(1){
            color: #333;
            font-size: toRem(23);
            font-weight: bold;
            margin-bottom: toRem(10);
        }
        div:nth-child(2){
            color: #555;
            font-size: toRem(14);
            margin-bottom: toRem(20);
        }
        div:nth-child(3){
            margin-bottom: toRem(10);
            img{
                width: toRem(220);
                height: toRem(220);
            }
        }
        div:nth-child(4){
            color: #333;
            font-size: toRem(14);
        }
    }
    img{
        width: 200px;
        height: 200px;
    }
}
